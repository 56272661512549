@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-ext-300-normal.8895cd13.woff2") format("woff2"), url("roboto-cyrillic-ext-300-normal.6e6800bb.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-300-normal.303b9afa.woff2") format("woff2"), url("roboto-cyrillic-300-normal.c2935f14.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-ext-300-normal.73ef051e.woff2") format("woff2"), url("roboto-greek-ext-300-normal.e6d63256.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-300-normal.962b7627.woff2") format("woff2"), url("roboto-greek-300-normal.9def6de0.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-vietnamese-300-normal.6fb53ea7.woff2") format("woff2"), url("roboto-vietnamese-300-normal.7a36c4ce.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-ext-300-normal.51d7b0c4.woff2") format("woff2"), url("roboto-latin-ext-300-normal.9ee5529a.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.d07a8413.woff2") format("woff2"), url("roboto-latin-300-normal.20b3ab25.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-cyrillic-ext-400-normal.a08b2a3e.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-cyrillic-400-normal.a5a342c2.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-greek-ext-400-normal.e526b0c1.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-greek-400-normal.8ad61867.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-vietnamese-400-normal.80131064.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-latin-ext-400-normal.7013a0e5.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-latin-400-normal.0a2ad554.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.216ff506.woff2") format("woff2"), url("roboto-cyrillic-ext-500-normal.4cacad2c.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.4c9891c0.woff2") format("woff2"), url("roboto-cyrillic-500-normal.9867a510.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.63a0634f.woff2") format("woff2"), url("roboto-greek-ext-500-normal.6ed21af2.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.9f44a402.woff2") format("woff2"), url("roboto-greek-500-normal.1ddb8d95.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.11348a9c.woff2") format("woff2"), url("roboto-vietnamese-500-normal.dbc08370.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.922e2b56.woff2") format("woff2"), url("roboto-latin-ext-500-normal.7f0ca47d.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-latin-500-normal.e1ea9bdc.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-ext-700-normal.0921acac.woff2") format("woff2"), url("roboto-cyrillic-ext-700-normal.43eb0c1d.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-700-normal.96126479.woff2") format("woff2"), url("roboto-cyrillic-700-normal.391c23c0.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-ext-700-normal.00d528bb.woff2") format("woff2"), url("roboto-greek-ext-700-normal.f2982eb0.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-700-normal.bc3ad99c.woff2") format("woff2"), url("roboto-greek-700-normal.463ab9a3.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-vietnamese-700-normal.a1447a12.woff2") format("woff2"), url("roboto-vietnamese-700-normal.3a47730c.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-ext-700-normal.372a1858.woff2") format("woff2"), url("roboto-latin-ext-700-normal.de7451f0.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.0e98339e.woff2") format("woff2"), url("roboto-latin-700-normal.4b7de361.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url("Montserrat-Thin.6e27a062.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("Montserrat-Regular.a32550f7.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("Montserrat-Medium.dc3ce8d6.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("Montserrat-Bold.96db3e27.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("Montserrat-Black.6699450c.ttf") format("truetype");
}

@font-face {
  font-family: Belfast Grotesk;
  font-style: normal;
  font-weight: 900;
  src: url("BelfastGrotesk-Black.d695d664.ttf") format("truetype");
}

:root {
  --primary: #00ae7b;
  --primary-light: #23f6cd;
  --primary-dark: #00332a;
  --secondary-light: #703a62;
  --secondary: #4d0a3b;
  --secondary-dark: #190918;
  --bgLight: #f2f2f2;
  --bgDark: #0e121a;
  --bg: #253042;
  --gradientPrimary: linear-gradient(90deg, #00332a 0%, #00ae7b 100%);
  --gradientSecondary: linear-gradient(90deg, #190918 0%, #4d0a3b 100%);
  --small-screen-width: 900px;
}

body {
  color: #fff;
  background-color: var(--bgDark);
  min-height: 80vh;
  font-family: Montserrat, "sans-serif";
  font-style: normal;
}

h1 {
  text-transform: uppercase;
  margin: 0;
  font-size: 58px;
  font-weight: 700;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 8px;
  font-size: 42px;
  font-weight: 700;
}

h3 {
  text-transform: uppercase;
  margin: 0;
  font-size: 32px;
  font-weight: 700;
}

h4 {
  margin: 0;
  font-size: 21px;
  font-weight: 700;
}

h5 {
  font-size: 18px;
  font-weight: 400;
}

button {
  font-size: 16px;
  font-weight: 700;
}

p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}

.page-wrapper {
  max-width: 1200px;
}

.section {
  padding-top: 120px;
  padding-bottom: 60px;
}

.center {
  margin: auto;
}

.clickable {
  cursor: pointer;
}

a {
  color: #fff;
  text-decoration: none;
}

.g8z-RG_container {
  background-color: #253042;
  min-height: 100vh;
}

.sVY6sa_eventContainer {
  flex-flow: wrap;
  gap: 30px 15px;
  display: flex;
}

.A_g_ua_container {
  color: #fff;
  background-color: #1e1e1e;
  border-radius: 8px;
  width: 400px;
  margin: 16px;
  padding: 16px;
}

.A_g_ua_avatar {
  background-color: #3f51b5;
}

.q-hzAa_seedContainer {
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  display: flex;
}

.q-hzAa_eventForm {
  max-width: 300px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.twrMLa_container {
  min-height: 20vh;
}

.twrMLa_paper {
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  padding: 20px;
  display: flex;
}

.twrMLa_cardContainer {
  flex-flow: wrap;
  gap: 30px;
  display: flex;
}

.ib4g6a_paper {
  width: 320px;
  padding: 30px 40px;
  position: relative;
}

.ib4g6a_inputField {
  width: 100%;
}

.ib4g6a_fieldContainer {
  flex-direction: column;
  gap: 25px;
  margin-top: 20px;
  display: flex;
}

.ib4g6a_deleteButton {
  cursor: pointer;
  padding: 10px;
  transition: all .3s;
  position: absolute;
  top: 20px;
  right: 10px;
}

.ib4g6a_deleteButton:hover {
  color: #fff;
  background-color: #f44336;
}

.OWZDHW_container {
  min-height: 20vh;
}

.OWZDHW_paper {
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  padding: 20px;
  display: flex;
}

.OWZDHW_cardContainer {
  flex-flow: wrap;
  gap: 30px;
  display: flex;
}

.WFU7Oq_paper {
  width: 400px;
  margin: 16px 0;
  padding: 15px;
}

.WFU7Oq_playerName {
  text-transform: none;
  margin-bottom: 40px;
  font-size: 2rem;
}

.WFU7Oq_fieldContainer {
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  display: flex;
}

.WFU7Oq_inputField {
  width: 100%;
}

.WFU7Oq_deleteButton {
  float: left;
  margin-right: auto;
}

.WFU7Oq_selector {
  float: right;
  margin-left: auto;
}

.btqijG_socialInput {
  align-items: center;
  display: flex;
}

.btqijG_inputField {
  flex: 1;
}

.GJONNW_container {
  min-height: 20vh;
}

.GJONNW_seedContainer {
  flex-flow: wrap;
  gap: 20px 40px;
  display: flex;
}

.GJONNW_createMapDocumentButton {
  justify-content: end;
  max-width: 80%;
  margin-bottom: 20px;
  display: flex;
}

.Il8b-G_container {
  flex-direction: column;
  width: 350px;
  padding: 35px 40px;
  display: flex;
  position: relative;
}

.Il8b-G_started {
  background-color: #2b2a2a;
  border: 1px solid #713028;
}

.Il8b-G_active {
  border: 1px solid #4e734e;
}

.Il8b-G_statContainer {
  width: 100%;
  margin-top: 20px;
}

.Il8b-G_buttonContainer {
  justify-content: flex-end;
  margin-top: 20px;
  display: flex;
}

.Il8b-G_switchPosition {
  position: absolute;
  top: 10px;
  right: 20px;
}

.C99bDW_container {
  background-image: url("bg.65e6977e.jpg");
  height: 100vh;
}

.C99bDW_sideBar {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
  height: 100%;
  margin-left: auto;
  padding: 30px;
  display: flex;
}

.C99bDW_loginFormContainer {
  margin-top: 20px;
}

._6BcpRa_container {
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

._6BcpRa_sideBar {
  text-align: center;
  padding: 60px 40px;
}

._1P-DKq_container, .rzqTlG_container {
  min-height: 20vh;
}

.rzqTlG_buttonContainer {
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.rzqTlG_seedContainer {
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.b0-20q_container, .jUsQtG_container {
  min-height: 20vh;
}

.jUsQtG_seedContainer {
  flex-flow: wrap;
  gap: 20px 40px;
  display: flex;
}

.Zrj0SG_container {
  flex-flow: column wrap;
  align-content: center;
  gap: 20px;
  min-height: 20vh;
  display: flex;
}

.Zrj0SG_buttonContainer {
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  margin: 0 auto;
  display: flex;
}

.ld7Scq_container {
  z-index: 100000;
  background-color: #121212;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.ld7Scq_loadingContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ld7Scq_highlightContainer {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.ld7Scq_informationContainer {
  text-align: center;
  flex-flow: column wrap;
  align-content: center;
  gap: 3px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.ld7Scq_ratingContainer {
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.ld7Scq_ratingButton {
  color: #fff;
  cursor: pointer;
  background-color: #e1ad0000;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2rem;
  transition: background-color .2s ease-in-out;
}

.ld7Scq_ratingButton:hover {
  background-color: #e1ad00;
}

.YniwDa_container {
  justify-content: center;
  width: 100%;
  display: flex;
}

.YniwDa_table {
  border-collapse: collapse;
  width: 100%;
}

.YniwDa_table th {
  color: #fff;
  text-align: center;
  background-color: #121212;
  border: 1px solid #fff;
  padding: 5px;
}

.YniwDa_table td {
  color: #fff;
  text-align: center;
  background-color: #1e1e1e;
  border: 1px solid #fff;
  padding: 5px;
}

.YniwDa_clipLink {
  color: #fff;
  text-decoration: underline;
  transition: color .2s ease-in-out;
}

.YniwDa_clipLink:hover {
  color: #e1ad00;
}

.YniwDa_ratingStars {
  padding-right: 5px;
}

.oLx6xW_container {
  min-height: 20vh;
}

.oLx6xW_createMapDocumentButton {
  justify-content: end;
  max-width: 80%;
  margin-bottom: 20px;
  display: flex;
}
/*# sourceMappingURL=index.2a63da29.css.map */
